import { Box } from '@mui/material';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import NavBar from '../../shared-component/NavBar';
import Drawer from '../../shared-component/drawer/Drawer';
import { navBar } from '../../../Config/Constants';
import Dashboard from '../../../asset/icons/Dashboard';
import Property from '../../../asset/icons/Property';
import Sales from '../../../asset/icons/Sales';
import Settings from '../../../asset/icons/Settings';
import Analytics from '../../../asset/icons/Analytics';
import PlanMap from '../properties/PlanMap';
import Artical from '../../../asset/icons/Artical';
import Hub from '../../../asset/icons/Hub';
import UserContext from '../../../context/UserContext';
import Gallary from '../../../asset/icons/Gallary';
import WebsiteIcon from '../../../asset/icons/WebsiteIcon';
import Loading from '../../shared-component/Loading/Loading';
import { useMediaQuery } from 'react-responsive';
import GroupIcon from '../../../asset/icons/GroupIcon';
import PercentIcon from '../../../asset/icons/PercentIcon';
import PartnerIcon from '../../../asset/icons/PartnerIcon';
import DocumentsIcon from '../../../asset/icons/DocumentsIcon';

function Demo({
  loading,
  userDetails
}) {
  const { id, webId } = useParams();
  const [width, setWidth] = useState(300);
  const { isAffiliate, isPartner } = useContext(UserContext);
  
  const [contentWidth, setContentWidth] = useState('calc(100% - 299px)');

  const mobile = useMediaQuery({ query: '(max-width: 760px)' });

  const handleSidebar = () => {
    if (width === 0) {
      setWidth(300);
      setContentWidth('calc(100% - 299px)')
    } else if (width === 300) {
      setWidth(0);
      setContentWidth('100%')
    }
  };

  useEffect(() => {
    if (mobile) {
      setWidth(0);
      setContentWidth('100%')
    } else {
      setWidth(300);
      setContentWidth('calc(100% - 299px)')
    }
  }, [mobile]);

  const sideNavForAffiliate = [
    {
      id: '1',
      title: 'Dashboard',
      path: 'dashboard',
      subtitles: [],
      icon: (color) => {
        return <Dashboard color={color} />;
      },
    },
    {
      id: '2',
      title: 'Conversions',
      path: 'conversions',
      subtitles: [],
      icon: (color) => {
        return <Hub color={color} />;
      },
    },
  ];

  const sideNavForPartner = [
    {
      id: '1',
      title: 'Dashboard',
      path: 'partner-dashboard',
      subtitles: [],
      icon: (color) => {
        return <Dashboard color={color} />;
      },
    },
    {
      id: '2',
      title: 'Transactions',
      path: 'transactions',
      subtitles: [],
      icon: (color) => {
        return <PercentIcon color={color} />;
      },
    },
  ]

  const developerNav = [
    {
      id: '2',
      title: 'Properties',
      path: 'properties',
      subtitles: [],
      icon: (color) => {
        return <Property color={color} />;
      },
    },
    {
      id: '9',
      title: 'Gallery',
      path: 'gallary',
      subtitles: [],
      icon: (color) => {
        return <Gallary color={color} />;
      },
    },
    {
      id: '7',
      title: 'Forms',
      path: 'forms',
      subtitles: [],
      icon: (color) => {
        return <Artical color={color} />;
      },
    },
    {
      id: '10',
      title: 'Websites',
      path: 'websites',
      subtitles: [],
      icon: (color) => {
        return <WebsiteIcon color={color} />;
      },
    },
    {
      id: '5',
      title: 'Settings',

      icon: (color) => {
        return <Settings color={color} />;
      },
      subtitles: [
        { id: '6', title: 'Custom Code', path: 'custom-code' },
      ],
    },
  ]
  const salesNav = [
    {
      id: '1',
      title: 'Dashboard',
      path: 'dashboard',
      subtitles: [],
      icon: (color) => {
        return <Dashboard color={color} />;
      },
    },
    {
      id: '2',
      title: 'Properties',
      path: 'properties',
      subtitles: [],
      icon: (color) => {
        return <Property color={color} />;
      },
    },

    {
      id: '3',
      title: 'Sales',
      icon: (color) => {
        return <Sales color={color} />;
      },
      subtitles: [
        { id: '1', title: 'Leads', path: 'leads' },
        { id: '2', title: 'Bookings', path: 'bookings' },
      ],
    },

    {
      id: '4',
      title: 'Analytics',
      path: 'analytics',
      subtitles: [],

      icon: (color) => {
        return <Analytics color={color} />;
      },
    },
  ]
  const marketingNav = [
    {
      id: '1',
      title: 'Dashboard',
      path: 'dashboard',
      subtitles: [],
      icon: (color) => {
        return <Dashboard color={color} />;
      },
    },
    {
      id: '2',
      title: 'Properties',
      path: 'properties',
      subtitles: [],
      icon: (color) => {
        return <Property color={color} />;
      },
    },
    {
      id: '4',
      title: 'Analytics',
      path: 'analytics',
      subtitles: [],

      icon: (color) => {
        return <Analytics color={color} />;
      },
    },
    {
      id: '9',
      title: 'Gallery',
      path: 'gallary',
      subtitles: [],
      icon: (color) => {
        return <Gallary color={color} />;
      },
    },
    {
      id: '7',
      title: 'Forms',
      path: 'forms',
      subtitles: [],
      icon: (color) => {
        return <Artical color={color} />;
      },
    },

    {
      id: '8',
      title: 'Affiliate',
      path: 'affiliate',
      subtitles: [],
      icon: (color) => {
        return <Hub color={color} />;
      },
    },
    {
      id: '10',
      title: 'Websites',
      path: 'websites',
      subtitles: [],
      icon: (color) => {
        return <WebsiteIcon color={color} />;
      },
    },
    {
      id: '5',
      title: 'Settings',

      icon: (color) => {
        return <Settings color={color} />;
      },
      subtitles: [
        {
          id: '1',
          title: 'User Management',
          path: 'user-management',
        },
        { id: '2', title: 'Specification', path: 'specification' },
        { id: '3', title: 'Organization', path: 'organization' },
        { id: '5', title: 'Design template', path: 'template_design' },
        { id: '6', title: 'Custom Code', path: 'custom-code' },
      ],
    },
  ]
  const sidenav = [
    {
      id: '1',
      title: 'Dashboard',
      path: 'dashboard',
      subtitles: [],
      icon: (color) => {
        return <Dashboard color={color} />;
      },
    },
    {
      id: '2',
      title: 'Properties',
      path: 'properties',
      subtitles: [],
      icon: (color) => {
        return <Property color={color} />;
      },
    },

    {
      id: '3',
      title: 'Sales',
      icon: (color) => {
        return <Sales color={color} />;
      },
      subtitles: [
        { id: '1', title: 'Leads', path: 'leads' },
        { id: '2', title: 'Bookings', path: 'bookings' },
      ],
    },

    {
      id: '4',
      title: 'Analytics',
      path: 'analytics',
      subtitles: [],

      icon: (color) => {
        return <Analytics color={color} />;
      },
    },
    {
      id: '9',
      title: 'Gallery',
      path: 'gallary',
      subtitles: [],
      icon: (color) => {
        return <Gallary color={color} />;
      },
    },
    {
      id: '7',
      title: 'Forms',
      path: 'forms',
      subtitles: [],
      icon: (color) => {
        return <Artical color={color} />;
      },
    },

    {
      id: '8',
      title: 'Affiliate',
      path: 'affiliate',
      subtitles: [],
      icon: (color) => {
        return <Hub color={color} />;
      },
    },
    {
      id: '10',
      title: 'Websites',
      path: 'websites',
      subtitles: [],
      icon: (color) => {
        return <WebsiteIcon color={color} />;
      },
    },
    
    // {
    //   id: '11',
    //   title: 'Partners',
    //   path: 'partners',
    //   subtitles: [],
    //   icon: (color) => {
    //     return <PartnerIcon color={color} />;
    //   },
    // },
    {
      id: '12',
      title: 'Documents',
      path: 'documents',
      subtitles: [],
      icon: (color) => {
        return <DocumentsIcon color={color} />;
      },
    },
    {
      id: '5',
      title: 'Settings',

      icon: (color) => {
        return <Settings color={color} />;
      },
      subtitles: [
        {
          id: '1',
          title: 'User Management',
          path: 'user-management',
        },
        { id: '2', title: 'Specification', path: 'specification' },
        { id: '3', title: 'Organization', path: 'organization' },
        { id: '5', title: 'Design template', path: 'template_design' },
        { id: '6', title: 'Custom Code', path: 'custom-code' },
        { id: '7', title: 'Api', path: 'api-key' },
        // { id: '7', title: 'Billing', path: 'billing' },
      ],
    },
  ];
  
  const returnOutlet = useCallback(() => {
    return (
      <Box sx={{ width: `${contentWidth} !important` }}>
        <Outlet />
      </Box>
    );
  }, [width, setWidth, contentWidth, setContentWidth]);

  const returnNavigations = () =>{
    const role = userDetails?.role?.name?.toLowerCase();
    if(role === 'developer'){
      return developerNav
    }
    else if(role === 'marketing'){
      return marketingNav
    }
    else if(role === 'sales'){
      return salesNav
    }
    else{
      return sidenav
    }
  }

  const returnModules = () =>{
    if(isAffiliate){
      return sideNavForAffiliate
    }
    else if(isPartner){
      return sideNavForPartner
    }
    else{
      return returnNavigations()
    }
  }

  const returnComponent = () => {
    
    if (
      window.location.pathname === '/accept-invitation' ||
      window.location.pathname === `/map/${id}` ||
      window.location.pathname === `/map` ||
      window.location.pathname === `/websites/${webId}/themes` ||
      window.location.pathname === `/websites/${webId}/preview` ||
      window.location.pathname === `/pricing` || 
      window.location.pathname === `/pricing/`
    ) {
      return <Outlet />;
    } else {
      return (
        <>
          <div style={{ height: navBar.height, position: 'relative' }}>
            <NavBar width={width} handleSidebar={handleSidebar} />
          </div>
          <Box className="flex">
            <div>
              <Drawer
                navList={returnModules()}
                drawerWidth={width}
                isAffliate={isAffiliate}
                isPartner={isPartner}
              />
            </div>
            {returnOutlet()}
          </Box>
        </>
      );
    }
  };
  return (
    <>
     {loading ?
     <div className='h-screen flex flex-col items-center justify-center mx-auto'>
        <Loading />
     </div>
     :
     returnComponent()
     }
    </>
  );
}
export default Demo;
