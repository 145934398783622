import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../shared-component/table-component/DataGridTable';
 import formatISOString from '../../../../utils/formatISOString';
import LinkIt from '../component/LinkIt';
import { SecondaryButton } from "@assystant/mui-buttons";
import ManageColumns from '../component/ManageColumns';
import { apiService } from '../../../../constant/AuthConstant';
import returnError from '../../../../utils/ExportError';
import useExit from '../../../../utils/useExit';
import { enqueueSnackbar } from 'notistack';
import FilterFields from '../../../components/leads/Fields/FilterFields'
import Loading from '../../../shared-component/Loading/Loading';
import { useNavigate } from 'react-router-dom';
 
import OpenWithIcon from '@mui/icons-material/OpenWith';
import DetailSideDrawer from '../../ParentComponents/SideDrawer/DetailSideDrawer';
import { neighboursIcons } from '../../../../Config/Constants';
import EmptyCard from '../../../shared-component/card-component/EmptyCard';

const options = [
    {label: 'Name', checked: true, key:'name'},
    {label: 'Email', checked: true, key:'email'},
    {label: 'Phone', checked: true, key:'phone'},
]

const FormSubmissions = () =>{
    const navigate = useNavigate()
    const [openColumns, setOpenColumns] = useState(false);
   
    const [fields, setFields] = useState(options)

    const { exitFromPlatform } = useExit();
    const [loading, setLoading] = useState(false);
    const [formSubmissionList, setFormSubmissionsList] = useState([]);

    const [selectedForm, setSelectedForm] = useState(null);

    const [detailModal, setDetailModal] = useState(false);

    const [sideDrawer, setSideDrawer] = useState(false)
    const [leadLoading, setLeadLoading] = useState(false)
    const [selectedLead, setSelectedLead] = useState(null);
    const [selectedBooking, setSelectedBooking] = useState(null) 

    const [booking, setBooking] = useState({});
    const [leadList, setLeadList] = useState(null);
    const [details, setDetails] = useState(null)
    const [neighbourIcon, setNeighbourIcon] = useState(neighboursIcons[0])
    const [bookingLoading, setBookingLoading] = useState(false);
    const [notesLoading, setNotesLoading] = useState(false);
    const [notesList, setNotesList] = useState([])
    
  
    const handleSideDrawer = () => {
      setSideDrawer(!sideDrawer)
    }
  
    
    const handleDetailModal = () =>{
        setDetailModal(!detailModal);
    }

    const handleColumns = () =>{
        setOpenColumns(!openColumns)
    }

    
    const columns = [
        { field: 'name', headerName: 'Name',minWidth: 150},
        { field: 'email', headerName: 'Email',minWidth: 200,},
        { field: 'phone', headerName: 'Phone',minWidth: 200},
        { field: 'created', headerName: 'Submission Date',minWidth: 200, renderCell: (params) =>{
            return formatISOString(params?.row?.created)
        }},
        { field: 'source', headerName: 'Updated By', minWidth: 200, renderCell: (params) =>{
          return params?.row?.source === 'USER_SUBMITTED' ? (
            <div
              className='w-full'
              style={{
                background: '#24D315',
                borderRadius: '25px',
                padding: '5px 10px',
                color: '#fff',
                textAlign: 'center',
                fontFamily: 'Rubik',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 300,
                lineHeight: 'normal',
              }}
            >
              {`User Completed`}
            </div>
          ) 
          : 
          <div
          className='w-full'
              style={{
                background: '#FAD200',
                borderRadius: '25px',
                padding: '5px ',
                color: '#fff',
                textAlign: 'center',
                fontFamily: 'Rubik',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 300,
                lineHeight: 'normal',
              }}
            >
              {`AI Found`}
            </div>
          
      }},
        { field: '', headerName: '',minWidth: 250, renderCell: (params) =>{
            console.log('from bookings', params?.row?.bookings?.length)
            return <LinkIt label={params?.row?.bookings?.length > 0 ? "View Booking" : "View Lead"} navigate={() =>{
               if(params?.row?.bookings?.length === 0 && params?.row?.leads?.length === 0){
                enqueueSnackbar("Linked lead or booking details are not found", { variant: 'error' })
               }
               if(params?.row?.bookings?.length  > 0) {
                    setSelectedLead(null)
                    setSelectedBooking(params?.row?.bookings[params?.row?.bookings?.length - 1])
                    handleSideDrawer()
               }else {
                  if(params?.row?.leads?.length > 0){
                    setSelectedBooking(null)
                    setSelectedLead(params?.row?.leads[params?.row?.leads?.length - 1])
                    handleSideDrawer()
                  }
               }
            }} />
        }, renderHeader: () =>{
            return <div className='bg-[#FFFFFF]'></div>
          }},
        //   {
        //     field: 'actions',
        //     headerName: '',
        //     sortable: false,
        //     minWidth: 80,
        //     align:'center',
        //     renderCell: (params) => (
        //         <>
        //          <div className="cursor-pointer" onClick={(e)=>{
        //           e.stopPropagation()
        //           e.preventDefault()
        //           setSelectedForm(params.row)
        //           handleDetailModal()
        //          }}>
        //                 <OpenWithIcon color="error" className="!fill-[#FF5C5C]"  />
        //             </div>
        //         </>
        //         ),
        //   },
    ];
    const [updatedColumns, setUpdatedColumns] = useState(columns);

    const handleAllColumns = (data) =>{
        const cloned = [...columns];
        
        const updatedData = cloned?.filter((e) =>{
            const field = data?.find((f) =>{
                return e?.headerName === f?.label
            })
             
            if(field?.label === e?.headerName && field !== undefined){
                console.log('from field1', e)
                if(field?.checked === true){
                    console.log('from field2', e)
                    return e
                }
            }else{
                return e
            }
        })?.filter((e) => e !== undefined)
       
        setUpdatedColumns(updatedData)
        handleColumns(updatedData)
    }

    const handleCheckboxChange = (e, val)=>{
        if(val?.key === 'name'){
            const newData = fields.map((value,i)=>{
                if(value.key === 'name'){
                    return{
                        ...value,
                        checked: e.target.checked
                    }
                }
                return value
             })
             
             setFields(newData)
        }else if(val?.key === 'email'){
            const newData = fields.map((value,i)=>{
                if(value.key === 'email'){
                    return{
                        ...value,
                        checked: e.target.checked
                    }
                }
                return value
             })
              
             setFields(newData)
        }
        else if(val?.key === 'phone'){
            const newData = fields.map((value,i)=>{
                if(value.key === 'phone'){
                    return{
                        ...value,
                        checked: e.target.checked
                    }
                }
                return value
             })
             setFields(newData)
        }
        else if(val?.key === 'note'){
            const newData = fields.map((value,i)=>{
                if(value.key === 'note'){
                    return{
                        ...value,
                        checked: e.target.checked
                    }
                }
                return value
             })
             setFields(newData)
        }
      }

      const getFormSubmissions = (formId) =>{
        setLoading(true)
        apiService.get(`form_integration/submissions?form=${formId}`).then((res) =>{
        const updateData = res?.data?.map((e) =>{
            return {
                ...e,
                ...JSON.parse(e?.submission)
            }
        })
          setFormSubmissionsList(updateData)
          localStorage.removeItem('form-tab')
          setLoading(false)
        }).catch((error) =>{
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                  exitFromPlatform()
              }else{
                  enqueueSnackbar(returnedError, {variant:'error'})
                  setLoading(false)
              }
            }
        })
      }



      useEffect(() =>{
        if(selectedForm?.id){
          getFormSubmissions(selectedForm?.id)
        }
      }, [selectedForm])
      
       const handleOnClick = (event) =>{
        navigate(`/form-detail-preview/${event?.id}`)
        
       }

       const getNotes = (leadId) =>{
        setNotesLoading(true)
        apiService?.get(`leads/note/${leadId}/`).then((res) =>{
            console.log(res)
            setNotesLoading(false)
            setNotesList(res?.data)
        })
        .catch((error) =>{
            setNotesLoading(false)
            if(error.response?.data){
              const returnedError = returnError(error.response?.data)
              if(returnedError === 'Given token not valid for any token type'){
                exitFromPlatform()
            }else{
                enqueueSnackbar(returnedError, {variant:'error'})
            }
            }})
    }

    const fetchleadDetails = (leadId) => {
        setLeadLoading(true)
        apiService.get(`leads/prospect/${leadId}/`).then((res) =>{
            if(res?.data?.length === 0){
                setLeadList([])
             }
            else if(res?.data?.length > 1){
            const arrayData = [...res?.data];
            arrayData.shift();
             const updateObj = {
                 ...res?.data[0],
                 history: arrayData
             }
             const list = [updateObj];
           
        
           
           setDetails(res?.data[0])
           setLeadList(list)
           getNotes(leadId)
         }else{
            setDetails(res?.data[0])
            setLeadList(res?.data)
            getNotes(leadId)
         }
         setLeadLoading(false)
        }).catch((error) =>{
         setLoading(false)
         if(error.response?.data){
           const returnedError = returnError(error.response?.data)
           if(returnedError === 'Given token not valid for any token type'){
             exitFromPlatform()
         }else{
             enqueueSnackbar(returnedError, {variant:'error'})
             setLeadLoading(false)
         }
       }
        })
     }

     const getBooking = (bookingId) => {
        if (bookingId) {
          setBookingLoading(true);
          apiService
            .get(`booking/entries/${bookingId}/`)
            .then((res) => {
              if (res?.data) {
                if(res?.data?.profile_icon){
                  const findIcon = neighboursIcons?.find((e) =>{
                    return e?.name?.toLowerCase() === res?.data?.profile_icon
                  })
                  setNeighbourIcon(findIcon)
                  setBooking({...res?.data, profile_icon: findIcon});
                }else{
                  setBooking({...res?.data});
                }
              }
              setBookingLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              if (error.response?.data) {
                const returnedError = returnError(error.response?.data);
                if (returnedError === 'Given token not valid for any token type') {
                  exitFromPlatform();
                } else {
                  enqueueSnackbar(returnedError, { variant: 'error' });
                  setBookingLoading(false)
                }
              }
            });
        }
      };

      const navigateToForm = (navigateTo) =>{
        navigate(navigateTo)
      }
      

    return (
       <div className=''>
         <div className='flex mb-4'>
         <div className='w-52'>
            <FilterFields 
                value={selectedForm}
                setValue={setSelectedForm}
                choicesAPI="form_integration/forms/"
                titleKey="template_name"
                placeholder="Select"
          />
          </div>
            
         </div>
        {selectedForm?.id ?
        <>
          {
            loading ? <Loading />
            :
           <>
            {formSubmissionList?.length === 0 ?
             <EmptyCard 
                title='Form submissions are not available for this form'
                subtitle='Please select another for to see the form submissions list'
             />
            :
            <DataGridTable 
            columns={updatedColumns}
            rows={formSubmissionList}
            actionWidth={100}
            handleEvent={handleOnClick}
          />}
           </>
        }
        </>
        :
        <EmptyCard 
          title='Please select a form'
          subtitle='Form selection will show the form submissions specific to that form'
        />}
        {openColumns &&(
            <ManageColumns 
               fields={fields}
               options={options}
               open={openColumns}
               setFields={setFields}
               onClose={handleColumns}
               handleAllColumns={handleAllColumns}
               handleCheckboxChange={handleCheckboxChange}
            />
        )}

        {sideDrawer && (
            <DetailSideDrawer 
                sideTitle={selectedBooking?.id ? `See in Bookings` : (selectedLead?.id ? `See in Leads` : "")}
                sideButtonClickHandler={() =>{
                  if(selectedBooking?.id){
                      navigateToForm(`/booking/${selectedBooking?.id}`)
                  }else if(selectedLead?.id){
                      navigateToForm(`/leads/${selectedLead?.session}`)
                  }
                }}
                title={selectedBooking?.id ? `Booking ${`- ${selectedBooking?.name}`}` : `Lead ${`- ${selectedLead?.name}`}`}
                open={sideDrawer}
                onClose={handleSideDrawer}
                fetchCallback={() =>{
                    if(selectedBooking?.id){
                        getBooking(selectedBooking?.id)
                    }else{
                        fetchleadDetails(selectedLead?.session)
                    }
                }}
                formData={selectedBooking?.id ? {
                    booking: booking,
                    neighbourIcon: neighbourIcon
                  } : {
                    details: details,
                    leadList: leadList,
                    notesLoading: notesLoading,
                    notesList: notesList
                  }}
                isBookingComponent={selectedBooking?.id ? true : false}
                isLeadComponent={selectedLead?.id ? true : false}
                loading={selectedBooking?.id ? bookingLoading : leadLoading}
            />
        )}
       </div>
       
    )
}

export default FormSubmissions