import React, { Suspense, useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
} from 'react-router-dom';
import Login from '../AuthenticationPage/AuthPages/Login';
import SignUp from '../AuthenticationPage/AuthPages/SignUp';
import ForgotPassword from '../AuthenticationPage/AuthPages/ForgotPassword';

import Demo from '../components/DemoPage/Demo';
import Dashboard from '../components/dashboard/Dashboard';
import Leads from '../components/leads/Leads';
import ChannelPartner from '../components/channelPartner/ChannelPartner';
import PropertyType from '../components/setting/property-types/PropertyType';
import Analysis from '../components/analysis/Analysis';

import Organization from '../components/setting/organization/Organization';
import Objects from '../components/setting/objects/Objects';
import Specification from '../components/setting/specification/Specification';
import InventoryOverview from '../components/properties/InventoryOverview';
import ProfileOverview from '../components/profile/ProfileOverview';
import MapPage from '../components/DemoPage/MapPage';
import Integrations from '../components/setting/integration/Integrations';
import Otp from '../AuthenticationPage/AuthPages/Otp';
import Permissions from '../../Permission/Permissions';
import Property from '../components/properties/Property';
import UserContext from '../../context/UserContext';
import Overview from '../components/properties/Overview';

import HotspotTabs from '../components/properties/newMap/HotspotTabs';
import TempDesign2 from '../components/setting/template_design/TempDesign2';
 
import { TokenStorage, apiService } from '../../constant/AuthConstant';

import OrganizationSetup from '../AuthenticationPage/AuthPages/OrganizationSetup';
import CreateNewPassword from '../AuthenticationPage/AuthPages/CreateNewPassword';
import UserInvitation from '../AuthenticationPage/AuthPages/UserInvitation';
import { enqueueSnackbar } from 'notistack';
import usePersistantTimer from '../../utils/Timer';

import CustomCode from '../components/setting/CustomCode/CustomCode';
import CustomOverview from '../components/setting/CustomCode/CustomCodeComponents/CustomOverview';
import Forms from '../components/forms/Form';
import FormDetail from '../components/forms/component/FormDetail';
import Template7 from '../components/setting/template_design/Template7';
import Booking from '../components/booking/Booking';
import BookingDetails from '../components/booking/component/BookingDetails';
import Affiliate from '../components/Affiliate/Affiliate';
import AffiliateDetails from '../components/Affiliate/tabs/AffiliateDetails';
import returnError from '../../utils/ExportError';
import useExit from '../../utils/useExit';
import AcceptInvitation from '../AuthenticationPage/AcceptInvitation';

import AffiliatePortalDashboard from '../components/AffiliatePortal/Dashboard/AffiliatePortalDashboard';
import NotFound from '../AuthenticationPage/AuthPages/NotFound';

import Container from '../components/Maps/component/Container';
import AffiliatePortalConversions from '../components/AffiliatePortal/Conversions/AffiliatePortalConversions';
import AffiliateProfile from '../components/AffiliatePortal/Conversions/Details/AffiliateProfile';
import Templates from '../components/Templates/Templates';
import LeadDetails from '../components/leads/components/LeadDetails';
import GallaryModule from '../components/Gallary/GallaryModule';
import UpdatesDetails from '../components/properties/PropertyUpdates/Details/UpdatesDetails';
import FormDetailPreview from '../components/forms/component/FormDetailPreview';
import UnSubscribe from '../AuthenticationPage/AuthPages/Unsubscribe';
import WebsiteComponent from '../components/Websites/WebsitesComponent';
import ThemesComponent from '../components/Websites/components/Themes/ThemesComponent.js';
 
import WebsitePreviewContainer from '../components/Websites/components/Preview/WebsitePreviewContainer.js';
import WebsiteInternalComponent from '../components/Websites/components/InteranalComponents/WebsiteInternalComponent.js';
import WebsitePageDetails from '../components/Websites/components/InteranalComponents/tabs/Pages/WebsitePageDetails.js';
import WebsiteMenuDetails from '../components/Websites/components/InteranalComponents/tabs/Menus/WebsiteMenuDetails.js';
import UserManagementComponent from '../components/setting/UserManagement/UserManagementComponent.js';
import ActivateAccount from '../AuthenticationPage/AuthPages/ActivateAccount.js';
import Clients from '../components/Partner/clients/Clients.js';
import PartnerDashboard from '../components/Partner/dashboard/PartnerDashboard.js';
import PartnerProfile from '../components/Partner/profile/PartnerProfile.js';
import Billing from '../components/setting/billing/Billing.js';
import PricingComponent from '../components/Pricing/PricingComponent.js';
 
import Partners from '../components/Partner/admin/Partners.js';
import DocumentsModule from '../components/Documents/DocumentsModule.js';
import PublicForm from '../components/PublicPages/PublicForm.js';
import StripeKey from '../components/StripeKey/StipeKey.js';
 

const IDLE_TIMEOUT = 2 * 60 * 60 * 1000; // 1 minute

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function PublicConfig() {
  const navigate = useNavigate();
  const [idleTimeout, setIdleTimeout] = useState(IDLE_TIMEOUT);
  const [files, setFiles] = useState([]);
  const logedProfile = localStorage.getItem('profile');
  const [selectedTem, setSelectedTemp] = useState(1);
  const [property, setProperties] = useState([]);
  const [leads, setLeadList] = useState([]);
  const [inv, setInv] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const userDetail = JSON.parse(localStorage.getItem('profiledetails'));
  const [filesUrl, setFilesUrl] = useState([]);
  const [count, start, pause, reset] = usePersistantTimer(idleTimeout);
  const { exitFromPlatform } = useExit();
  const [organization, setOrganization] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [isAffiliate, setIsAffiliate] = useState(false);
  const [affiliateOrganizations, setAffiliateOrganizations] = useState([]);
  const [affiliateOrgLoading, setAffiliateOrgLoading] = useState(false);
  const [retrieveUserLoading, setRetrieveLoading] = useState(false);
  const [userFromOrganization, setUserFromOrganization] = useState({
    role: {
      name: 'Admin',
    },
  });
  const [isPartner, setIsPartner] = useState(false);

  const refreshToken = async () => {
    apiService
      .post(`jwt/refresh/`, {
        refresh: TokenStorage.getRefreshToken(),
      })
      .then((res) => {
        if (res.status === 200) {
          TokenStorage.storeToken(res.data.access);
        }
      })
      .catch((err) => {
        pause();
        reset();
        TokenStorage.clear();
        localStorage.clear();
        enqueueSnackbar('Session Expired, Please login again.', {
          variant: 'default',
        });
        navigate('/');
      });
  };
  let timerId;
  useEffect(() => {
    if (logedProfile !== null) {
      timerId = setInterval(
        () => {
          refreshToken();
        },
        26 * 60 * 1000,
      );
    }

    return () => {
      clearInterval(timerId);
    };
  }, [logedProfile]);

  const handleSelect = (e, id) => {
    setSelectedTemp(id);
  };

  const isTokenValid = () => {
    apiService
      .post(`/jwt/verify/`, {
        token: TokenStorage.getToken(),
      })
      .then((res) => {
        console.log('from response', res);
        if (res.status === 200) {
          console.log('Yes it is valid');
        }
      })
      .catch((err) => {
        pause();
        reset();
        TokenStorage.clear();
        localStorage.clear();
        enqueueSnackbar('Session Expired, Please login again.', {
          variant: 'default',
        });
        navigate('/');
      });
  };

  useEffect(() => {
    if (count > idleTimeout && logedProfile !== null) {
      pause();
      reset();
      clearInterval(timerId);
      TokenStorage.clear();
      localStorage.clear();
      enqueueSnackbar('Session Expired', { variant: 'success' });
      navigate('/');
    }
  }, [count]);

  useEffect(() => {
    if (TokenStorage.getToken()) {
      isTokenValid();
    }
  }, [TokenStorage]);

  const templates = [
    {
      id: 2,
      value: 'TEMPLATE1',
      element: TempDesign2,
      handleSelect: handleSelect,
      selected: selectedTem,
    },
    {
      id: 3,
      value: 'TEMPLATE3',
      element: Template7,
      handleSelect: handleSelect,
      selected: selectedTem,
    },
  ];

  const currencies = [
    { id: 1, currencyCode: 'AUD', locale: 'en-AU', symbol: '$' },
    { id: 2, currencyCode: 'CAD', locale: 'en-CA', symbol: '$' },
    { id: 3, currencyCode: 'EUR', locale: 'en-EU', symbol: '€' },
    { id: 4, currencyCode: 'GBP', locale: 'en-GB', symbol: '£' },
    { id: 5, currencyCode: 'INR', locale: 'en-IN', symbol: '₹' },
    { id: 6, currencyCode: 'JPY', locale: 'ja-JP', symbol: '¥' },
    { id: 7, currencyCode: 'USD', locale: 'en-US', symbol: '$' },
  ];

  const fetchOrganization = () => {
    apiService
      .get(`organization/get-user-org/`)
      .then((res) => {
        if (res.data) {
          if (res?.data?.default_currency) {
            const findCurrency = currencies?.find((e) => {
              return e?.currencyCode === res?.data?.default_currency;
            });
            setSelectedCurrency(findCurrency);
          }
          setOrganization(res?.data);
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };

  const getAffiliateOrganizations = () => {
    setAffiliateOrgLoading(true);
    apiService
      .get(`affiliates/affiliate_organizations/`)
      .then((res) => {
        const list = res?.data?.map((e) => {
          return {
            ...e,
            title: e?.organization?.name,
          };
        });
        setAffiliateOrganizations(list);
        setAffiliateOrgLoading(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setAffiliateOrgLoading(false);
          }
        }
      });
  };

  const getUserFromOrganizations = () => {
    setRetrieveLoading(true);
    apiService
      .get(`account/user/get_details/`)
      .then((res) => {
        if (res?.data?.id) {
          if (res?.data?.userrole === 'Developer') {
            navigate('/properties');
          }
          setUserFromOrganization({
            ...res?.data,
            role: {
              name: res?.data?.userrole === '' ? 'Admin' : res?.data?.userrole,
            },
          });
        }
        setRetrieveLoading(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setRetrieveLoading(false);
          }
        }
      });
  };

  useEffect(() => {
    if(!TokenStorage?.getToken()){
      pause();
      reset();
    }
    if (logedProfile !== null && logedProfile?.remember_me === true) {
      setIdleTimeout(48 * 60 * 60 * 1000);
    } else {
      setIdleTimeout(IDLE_TIMEOUT);
    }
    if (
      logedProfile !== null &&
      TokenStorage.getProfile()?.is_affiliate === false &&
      TokenStorage.getProfile()?.is_consulting_partner === false
    ) {
      getUserFromOrganizations();
      fetchOrganization();
    }
    if (
      logedProfile !== null &&
      TokenStorage.getProfile()?.is_consulting_partner === true &&
      TokenStorage.getProfile()?.is_affiliate === false
    ) {
      setIsPartner(true);
    } else {
      setIsPartner(false);
    }
    if (
      logedProfile !== null &&
      TokenStorage.getProfile()?.is_consulting_partner === false &&
      TokenStorage.getProfile()?.is_affiliate === true
    ) {
      setIsAffiliate(true);
      if (localStorage.getItem('invitation-code') !== null) {
        navigate('/accept-invitation');
      }
      getAffiliateOrganizations();
    } else {
      setIsAffiliate(false);
    }
    console.log('from logedProfile', logedProfile, TokenStorage.getProfile());
  }, [logedProfile]);

  const returnFormattedCurrency = (locale, currencyCode, currencyValue) => {
    if (locale && currencyCode) {
      const formattedCurrency = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
      }).format(currencyValue);

      return formattedCurrency;
    } else {
      return `$${currencyValue}`;
    }
  };

  return (
    <UserContext.Provider
      value={{
        orgId: userDetail?.orgId,
        setFiles,
        files,
        setStatus,
        status,
        setFilesUrl,
        filesUrl,
        currencies,
        organization,
        selectedCurrency,
        fetchOrganization,
        returnFormattedCurrency,
        isAffiliate,
        isPartner,
        affiliateOrganizations,
        getAffiliateOrganizations,
        affiliateOrgLoading,
        userFromOrganization,
      }}
    >
      <Suspense>
        <ScrollToTop />
        {logedProfile === null ? (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/otp" element={<Otp />} />
            <Route path="/organizationsetup" element={<OrganizationSetup />} />
            <Route path="/createnewpassword" element={<CreateNewPassword />} />
            <Route
              path="/affiliate_invitation/:token"
              element={<UserInvitation />}
            />
            <Route
              path="/unsubscribe_notification/:token"
              element={<UnSubscribe />}
            />
            <Route
              path={`account/activate/:token`}
              element={<ActivateAccount />}
            />
            <Route path={`/signup/:token`} element={<SignUp />} />
            <Route path={`/public-form/form/:id`} element={<PublicForm />} />
            {/* <Route path={`/pricing`} element={<PricingComponent />} /> */}
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        ) : (
          <Routes>
            <Route
              path="/"
              element={
                <Demo
                  loading={retrieveUserLoading}
                  userDetails={userFromOrganization}
                />
              }
            >
              {TokenStorage.getProfile()?.is_affiliate === false &&
                TokenStorage.getProfile()?.is_consulting_partner === false && (
                  <>
                    <Route
                      path="dashboard"
                      element={
                        <Permissions permission="true">
                          <Dashboard
                            loading={loading}
                            property={property}
                            leads={leads}
                            inv={inv}
                          />
                        </Permissions>
                      }
                    />
                    <Route
                      path="leads"
                      element={<Leads property={property} />}
                    />
                    <Route
                      path="channel-partner"
                      element={<ChannelPartner />}
                    />
                    <Route path="properties" element={<Property />} />
                    <Route
                      path="/properties/:id"
                      element={
                        <Overview template={selectedTem} property={property} />
                      }
                    />
                    <Route
                      path="/properties/:id/:tab"
                      element={
                        <Overview template={selectedTem} property={property} />
                      }
                    />
                    <Route path="property-type" element={<PropertyType />} />
                    <Route path="analytics" element={<Analysis />} />
                    <Route
                      path="user-management"
                      element={<UserManagementComponent />}
                    />
                    <Route path="organization" element={<Organization />} />
                    <Route path="objects" element={<Objects />} />
                    <Route path="specification" element={<Specification />} />
                    <Route
                      path="/properties/:id/plan/:id"
                      element={<HotspotTabs template={selectedTem} />}
                    />
                    <Route
                      path="/properties/:id/inventory/:id"
                      element={<InventoryOverview inv={inv} />}
                    />
                    <Route path="profile" element={<ProfileOverview />} />
                    <Route
                      path="map-page"
                      element={
                        <MapPage
                          template={selectedTem}
                          organization={organization}
                        />
                      }
                    />
                    <Route path="integration" element={<Integrations />} />
                    <Route
                      path="template_design"
                      element={<Templates templates={templates} />}
                    />
                    <Route path="custom-code" element={<CustomCode />} />
                    <Route
                      path="custom-code/code/:id"
                      element={<CustomOverview />}
                    />
                    <Route path="forms" element={<Forms />} />
                    <Route path="forms/:id" element={<FormDetail />} />
                    <Route path="bookings" element={<Booking />} />
                    <Route path="booking/:id" element={<BookingDetails />} />
                    <Route path="affiliate" element={<Affiliate />} />
                    <Route
                      path="affiliate/:id"
                      element={<AffiliateDetails />}
                    />
                    <Route path="map/:id" element={<Container />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="leads/:id" element={<LeadDetails />} />
                    <Route path="gallary" element={<GallaryModule />} />
                    <Route path="updates/:id" element={<UpdatesDetails />} />
                    <Route
                      path="form-detail-preview/:id"
                      element={<FormDetailPreview />}
                    />
                    <Route path="websites" element={<WebsiteComponent />} />
                    <Route
                      path="websites/:webId/themes"
                      element={<ThemesComponent />}
                    />
                    <Route
                      path="websites/:webId/preview"
                      element={<WebsitePreviewContainer />}
                    />
                    <Route
                      path="websites/:webId"
                      element={<WebsiteInternalComponent />}
                    />
                    <Route
                      path="websites/:webId/page/:pageId"
                      element={<WebsitePageDetails />}
                    />
                    <Route
                      path="websites/:webId/menu/:menuId"
                      element={<WebsiteMenuDetails />}
                    />
                    {/* <Route
                      path="partner-dashboard"
                      element={<PartnerDashboard />}
                    /> */}
                    <Route path="transactions" element={<Clients />} />
                    {/* <Route
                      path="partner-profile"
                      element={<PartnerProfile />}
                    /> */}
                    {/* <Route path="billing" element={<Billing />} />
                    <Route path="pricing" element={<PricingComponent />} /> */}
                    {/* <Route path="partners" element={<Partners />} /> */}
                    <Route path="documents" element={<DocumentsModule />} />
                    <Route path="api-key" element={<StripeKey />} />
                  </>
                )}
              {TokenStorage.getProfile()?.is_affiliate === true &&
                TokenStorage.getProfile()?.is_consulting_partner === false && (
                  <>
                    <Route
                      path="/affiliate_invitation/:token"
                      element={<UserInvitation />}
                    />
                    <Route
                      path="accept-invitation"
                      element={<AcceptInvitation />}
                    />
                    <Route
                      path="dashboard"
                      element={<AffiliatePortalDashboard />}
                    />
                    <Route
                      path="conversions"
                      element={<AffiliatePortalConversions />}
                    />
                    <Route
                      path="affiliate-profile"
                      element={<AffiliateProfile />}
                    />
                    <Route path="*" element={<NotFound />} />
                  </>
                )}
              {TokenStorage.getProfile()?.is_affiliate === false &&
                TokenStorage.getProfile()?.is_consulting_partner === true && (
                  <>
                    <Route
                      path="partner-dashboard"
                      element={<PartnerDashboard />}
                    />

                    <Route path="transactions" element={<Clients />} />

                    <Route
                      path="partner-profile"
                      element={<PartnerProfile />}
                    />
                    <Route path="*" element={<NotFound />} />
                  </>
                )}
            </Route>
          </Routes>
        )}
      </Suspense>
    </UserContext.Provider>
  );
}
export default PublicConfig;
