import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn';
import { BoxEffects, headerButton } from '../../../Config/Constants';
import EditIcon from '@mui/icons-material/Edit';
import BreadCrumbWithActions from '../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import AddKeyModal from './modal/AddKeyModal';
import returnError from '../../../utils/ExportError';
import useExit from '../../../utils/useExit';
import { enqueueSnackbar } from 'notistack';
import { apiService } from '../../../constant/AuthConstant';
import Loading from '../../shared-component/Loading/Loading';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const arr1 = [{ title: 'API Key', url: '/' }];

const StripeKey = () => {
  const { exitFromPlatform } = useExit();
  const [api_key, setApi_key] = useState('');
  const [secret_key, setSecret_key] = useState('');
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);

  const handleAddModal = () => {
    setOpenAddModal(!openAddModal);
  };

  const getStripeKeys = () => {
    setLoading(true);
    apiService
      .get(`organization/get-user-org/`)
      .then((res) => {
        if (res?.data) {
          setApi_key(res?.data?.stripe_api_publishable_key);
          setSecret_key(res?.data?.stripe_api_secret_key);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };

  useEffect(() => {
    getStripeKeys();
  }, []);

  const copyAPIKey = () =>{
    navigator.clipboard.writeText(api_key)
    enqueueSnackbar('Publishable Key Copied', { variant: 'success' })
   }

   const copySecretKey = () =>{
    navigator.clipboard.writeText(secret_key)
    enqueueSnackbar('Secret Key Copied', { variant: 'success' })
   }

  return (
    <>
      <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: 'secondary.main',
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          paddingLeft: BoxEffects.margin,
          paddingRight: headerButton.marginRigt,
          position: 'sticky',
          top: '65px',
          background: 'white',
          zIndex: 99,
        }}
      >
        <div className="flex justify-between items-center">
          <BreadCrumbWithActions propArr={arr1} />
          <PrimaryBtn
            className="!mx-0"
            style={{
              boxShadow: 'none',
              margin: '0px !important',
              fontWeight: 300,
            }}
            title={api_key !== '' ? 'Edit Keys' : 'Add Keys'}
            onClick={handleAddModal}
          />
        </div>
      </Box>

      {loading ? (
        <Loading />
      ) : (
        <div className="m-8">
          <div className="flex flex-col gap-[5px]">
            <div className="text-xl font-normal text-[#353349] mb-8">Stripe API Keys</div>
            {api_key?.length === 0 ? (
              <div className="text-base font-normal text-[#B1AED1]">
                Click "Add Key" to add an api key
              </div>
            ) : (
              <div className="flex flex-col gap-[10px]">
                <div className="text-base font-normal text-[#353349] break-words">
                  Publishable Key
                </div>
                <div className='flex gap-[10px]'>
                  <div className="text-base font-normal text-[#B1AED1] break-words">
                    {`${api_key?.substring(0, 50)}...`}
                  </div>
                  <div className='cursor-pointer' onClick={copyAPIKey}>
                    <ContentCopyIcon className='!fill-[#004FE8]' />
                  </div>
                </div>
                <div className="text-base font-normal text-[#353349] break-words">
                  Secret Key
                </div>

                <div className='flex gap-[10px]'>
                  <div className="text-base font-normal text-[#B1AED1] break-words">
                    {`${secret_key?.substring(0, 50)}...`}
                  </div>
                  <div className='cursor-pointer' onClick={copySecretKey}>
                    <ContentCopyIcon className='!fill-[#004FE8]' />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {openAddModal && (
        <AddKeyModal
          open={openAddModal}
          onClose={handleAddModal}
          data={{
            stripe_api_publishable_key: api_key,
            stripe_api_secret_key: secret_key,
          }}
          onSuccessCallback={() => {
            getStripeKeys();
            handleAddModal();
          }}
        />
      )}
    </>
  );
};

export default StripeKey;
