import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import {
  Box,
  Card,
  Grid,
  FormControl,
  Select,
  Autocomplete,
  TextField,
  Chip,
  MenuItem,
  NativeSelect,
  Checkbox,
} from '@mui/material';
import GridComponent from '../../../shared-component/grid-component/GridComponent';
import { PrimaryButton, ErrorButton } from '@assystant/mui-buttons';
import BreadCrumbWithActions from '../../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import DetailsCard from '../../../shared-component/card-component/DetailsCard';
import { CheckBox, ExpandLess, ExpandMore } from '@mui/icons-material';
import { BoxEffects, headerButton, grids } from '../../../../Config/Constants';
import { SecondaryButton } from '@assystant/mui-buttons';
import Integration from '../../properties/newMap/linkadd/Integration';
import { useParams, useNavigate } from 'react-router-dom';
import { apiService } from '../../../../constant/AuthConstant';
import useExit from '../../../../utils/useExit';
import returnError from '../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import Loading from '../../../shared-component/Loading/Loading';
import FormPreview from './FormPreview';
import { BASE_URI } from '../../../../constant/AuthConstant';
import FormToolbar from './FormToolbar';

import { v4 } from 'uuid';

import StepperContainer from './FormStepperComponent/StepperContainer';
import UserContext from '../../../../context/UserContext';

import FieldComponent from './FormBuilderComponent/FieldComponent';
import { convertStringToKeys } from '../../../../utils/helper';
import * as yup from 'yup';
import SecondaryBtn from '../../../shared-component/button-component/SecondaryBtn';
import PrimaryBtn from '../../../shared-component/button-component/PrimaryBtn';
import ErrorBtn from '../../../shared-component/button-component/ErrorBtn';

const arr1 = [{ title: 'Form Settings', url: '/forms' }];

const fieldTypes = [
  { name: 'Textfield', id: 'TEXT' },
  { name: 'Dropdown - Single Selection', id: 'DROPDOWN' },
  { name: 'Dropdown - Multiple Selection', id: 'MULTISELECT' },
  { name: 'File Upload', id: 'FILEUPLOAD' },
  { name: 'Currency', id: 'CURRENCY' },
  { name: 'Checkbox', id: 'CHECKBOX' },
  { name: 'Radio', id: 'RADIO' },
  { name: 'Phone', id: 'PHONE' },
  { name: 'Email', id: 'EMAIL' },
  { name: 'Text Area', id: 'TEXTAREA' },
  { name: 'Name', id: 'NAME' },
  { name: 'Signature', id: 'SIGNATURE' },
];

const fileTypes = [
  { name: 'Document', value: 'docx' },
  { name: 'Presentation', value: 'ppt' },
  { name: 'Spreadsheet', value: 'xlsx' },
  { name: 'Drawing', value: 'dwg' },
  { name: 'PDF', value: 'pdf' },
  { name: 'Image', value: 'jpeg' },
  { name: 'Video', value: 'mp4' },
  { name: 'Audio', value: 'mp3' },
];

const FormDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const { exitFromPlatform } = useExit();
  const [breadcrumb, setBreadCrumb] = useState(arr1);
  const [formTitle, setFormTitle] = useState('');
  const [properties, setProperties] = useState([]);
  const [formStatus, setFormStatus] = useState('active');
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [applyOn, setApplyOn] = useState({ name: '', id: '' });
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [formHeader, setFormHeader] = useState('');
  const [formDescription, setFormDescription] = useState('');
  const [error, setError] = useState({});
  const { organization } = useContext(UserContext);
  const [namefield, setNameField] = useState({ name: '', id: '' });
  const [emailfield, setEmailField] = useState({ name: '', id: '' });
  const [phonefield, setPhoneField] = useState({ name: '', id: '' });
  const [allfields, setAllFields] = useState([]);
  const [documentsList, setDocumentsList] = useState([]);
  const [selectDocs, setSelectedDocs] = useState([]);
  const [openShareLink, setOpenShareLink] = useState(false)

  const handleOpenShareLink = () =>{
    setOpenShareLink(!openShareLink)
  }

  const [captureFields, setCaptureFields] = useState(false);

  const handleCapture = (e) => {
    setCaptureFields(e.target.checked);
  };

  const [formState, setFormState] = useState({
    header: {
      form_title: '',
      form_description: '',
    },
    fields: [],
    sections: [],
  });

  const [isActive, setIsActive] = useState(false);
  const sectionRef = useRef(null);

  const handleClickInside = () => {
    setIsActive(true);
  };

  const handleClickOutside = (event) => {
    if (sectionRef.current && !sectionRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // {
  //   step_name:'',
  // sections: [
  //   {
  //     section_name:'',
  //     fields: [
  //       {
  //         name: '',
  //         field_type: '',
  //         id: v4(),
  //         mandatory: true,
  //         helper_text: '',
  //         field_options: [{ id:'', value: ''}],
  //         document_types: [],
  //         maximum_file_size: '',
  //         maximumum_number_of_files: ''
  //       }
  //     ],
  //   }
  // ]
  // }

  const handleFormTitle = (e) => {
    const value = e?.target?.value || ''; // Ensure value is a string

    if (value === '') {
      setError((prevError) => ({
        ...prevError,
        title: 'Please add form label',
      }));
      setFormState((prevFormState) => ({
        ...prevFormState,
        header: {
          ...prevFormState.header,
          form_title: '',
        },
      }));
    } else if (value.length > 250) {
      setError((prevError) => ({
        ...prevError,
        title: 'You have exceeded the maximum limit of 250 characters',
      }));
    } else {
      // Clear the title error if it exists
      setError((prevError) => {
        const newError = { ...prevError };
        delete newError.title;
        return newError;
      });
      setFormState((prevFormState) => ({
        ...prevFormState,
        header: {
          ...prevFormState.header,
          form_title: value,
        },
      }));
    }
  };

  useEffect(() => {
    console.log('error.....', error);
  }, [error]);

  const handleFormDescription = (e) => {
    if (e?.target?.value === '') {
      const formD = {
        ...formState,
        header: {
          ...formState?.header,
          form_description: '',
        },
      };
      setFormState({ ...formD });
    } else if (e?.target?.value?.length > 1000) {
      setError({
        ...error,
        description: 'You have exeeded the maximum limit of 1000 characters',
      });
    } else {
      const formD = {
        ...formState,
        header: {
          ...formState?.header,
          form_description: e?.target?.value,
        },
      };
      const er = { ...error };
      delete er?.description;
      setError(er);
      setFormState({ ...formD });
    }
  };

  const hasValidFieldsOrSections = (data) => {
    if (data.fields.length > 0) {
      return true;
    }

    for (const section of data.sections) {
      console.log('from ddd.....', data, section);
      if (section.fields.length > 0) {
        return true;
      }
    }
    return false;
  };

  const handlePreview = () => {
    if (hasValidFieldsOrSections(formState) === false) {
      enqueueSnackbar('Please add fields first', { variant: 'default' });
    } else {
      setOpenPreview(!openPreview);
    }
  };

  const [openIntegrationModal, setOpenIntegrationModal] = useState(false);

  const toggleStatus = (e) => {
    setFormStatus(e.target.value);
  };

  const handleModal = () => {
    setOpenIntegrationModal(!openIntegrationModal);
  };

  const shareLink = () =>{
    navigator.clipboard.writeText(`${window.location.host}/public-form/form/${formDetails?.uuid}`)
    enqueueSnackbar('Link Copied', { variant: 'success' })
  }

  const handleLinkedProperties = (newValues) => {
    setSelectedProperties(newValues);
  };

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  const getDetails = () => {
    setLoading(true);
    apiService
      .get(`form_integration/forms/${id}`)
      .then((res) => {
        setBreadCrumb([
          ...breadcrumb,
          { title: res?.data?.template?.label, url: '/' },
        ]);
        if(res?.data?.fill_documents?.length > 0){
          const docs = res?.data?.fill_documents?.map((e) =>{
            return {
              ...e,
              title: e?.document?.title
            }
          })
          setSelectedDocs(docs)
        }
        setFormTitle(res?.data?.template_name);
        setFormStatus(res?.data?.form_status);
        setSelectedProperties(res?.data?.linked_properties);
        setNameField({
          id: res?.data?.default_name_field?.id,
          name: res?.data?.default_name_field?.label,
        });
        setEmailField({
          id: res?.data?.default_email_field?.id,
          name: res?.data?.default_email_field?.label,
        });
        setPhoneField({
          id: res?.data?.default_phone_field?.id,
          name: res?.data?.default_phone_field?.label,
        });
        if (res?.data?.apply_on !== null) {
          setApplyOn({
            name: toTitleCase(res?.data?.apply_on) || '',
            id: res?.data?.apply_on || '',
          });
        }
        setFormDetails(res?.data);
        if (res?.data?.auto_capture) {
          setCaptureFields(res?.data?.auto_capture);
        }
        setAllFields(res?.data?.template?.fields);
        const formDetail = {
          header: {
            form_title: res?.data?.template?.label,
            form_description: res?.data?.template?.description || '',
          },
          fields:
            res?.data?.template?.sections?.length === 0
              ? res?.data?.template?.fields?.map((e, i) => {
                  let newData = { ...e };
                  newData = {
                    ...e,
                    id: e?.id?.toString(),
                    name: e?.label,
                    helper_text: e?.placeholder,
                    field_options: e?.options?.map((f) => {
                      return {
                        value: f?.label,
                        id: f?.id.toString(),
                        sort_score: i,
                      };
                    }),
                    validations: [],
                    field_type: fieldTypes?.find((f) => f?.id === e?.type),
                    sort_score: i,
                    mandatory: e?.required,
                    maximum_files: e?.maximum_files || 1,
                    maximum_size: e?.maximum_size || 1,
                    file_types: e?.file_types || [],
                    allow_specific_file_type:
                      e?.allow_specific_file_type || false,
                    variable_name: e?.variable_name?.endsWith('_1') ? e?.variable_name : e?.variable_name?.concat('_1')
                  };
                  return newData;
                })
              : [],
          sections:
            res?.data?.template?.sections?.length > 0
              ? res?.data?.template?.sections?.map((z, index) => {
                  let newData = {
                    ...z,
                    section_name: z?.label,
                    section_description: z?.description,
                    sort_score: index,
                    uuid: z?.uuid ? z?.uuid : z?.id,
                    fields: z?.fields?.map((e, i) => {
                      let newData = { ...e };
                      newData = {
                        ...e,
                        id: e?.id?.toString(),
                        name: e?.label,
                        helper_text: e?.placeholder,
                        field_options: e?.options?.map((f) => {
                          return {
                            value: f?.label,
                            id: f?.id.toString(),
                            sort_score: i,
                          };
                        }),
                        validations: [],
                        field_type: fieldTypes?.find((f) => f?.id === e?.type),
                        sort_score: i,
                        mandatory: e?.required,
                        maximum_files: e?.maximum_files || 1,
                        maximum_size: e?.maximum_size || 1,
                        file_types: e?.file_types || [],
                        allow_specific_file_type:
                          e?.allow_specific_file_type || false,
                          variable_name: e?.variable_name?.endsWith('_1') ? e?.variable_name : e?.variable_name?.concat('_1')
                      };
                      return newData;
                    }),
                  };
                  delete newData?.id;
                  return newData;
                })
              : [],
        };
        console.log('from....................', formDetail);
        setFormState(formDetail);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (returnedError === 'Given token not valid for any token type') {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };
  const submitDefaultFields = (formid, name, email, phone) => {
    apiService
      .patch(`form_integration/forms/${formid}/default_fields/`, {
        default_name_field: name?.id,
        default_email_field: email?.id,
        default_phone_field: phone?.id,
      })
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data) {
            enqueueSnackbar('Details Updated', { variant: 'success' });
          }
          setSaving(false);
          setTimeout(() => {
            navigate('/forms');
          }, 1000);
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (returnedError === 'Given token not valid for any token type') {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setSaving(false);
          }
        }
      });
  };
  const editFormDetails = () => {
    if (formTitle === '') {
      enqueueSnackbar('Please add form title', { variant: 'error' });
      return;
    } else if (formState?.header?.form_title === '') {
      enqueueSnackbar('Please add form heading', { variant: 'error' });
      return;
    } else {
      if (applyOn?.id === 'enquiry' || applyOn?.id === 'booking') {
        if (namefield?.id === '') {
          enqueueSnackbar('Please select name field', { variant: 'error' });
        } else if (emailfield?.id === '') {
          enqueueSnackbar('Please select email field', { variant: 'error' });
        } else if (phonefield?.id === '') {
          enqueueSnackbar('Please select phone field', { variant: 'error' });
        } else {
          setSaving(true);

          let service;
          let api;

          if (id === 'new-form') {
            service = apiService.post;
            api = `form_integration/forms/`;
          } else {
            service = apiService.patch;
            api = `form_integration/forms/${id}/`;
          }

          const updateData = formState?.fields?.map((e, i) => {
            let newData = { ...e };
            newData = {
              ...e,
              label: e?.name,
              uuid: e?.uuid ? e?.uuid : e?.id,
              placeholder: e?.helper_text,
              type: e?.field_type?.id,
              options:
                e?.field_options?.map((f, i) => {
                  return {
                    label: f?.value,
                    sort_score: i,
                  };
                }) || [],
              validations: [],
              field_type: e?.field_type?.id,
              sort_score: i,
              required: e?.mandatory,
              maximum_files: e?.maximum_files || 1,
              maximum_size: e?.maximum_size || 1,
              file_types: e?.file_types || [],
              allow_specific_file_type: e?.allow_specific_file_type || false,
            };
            delete newData.id;
            delete newData.field_options;
            return newData;
          });

          const updateSections = formState?.sections?.map((e, i) => {
            let newData = {
              ...e,
              label: e?.section_name,
              description: e?.section_description,
              sort_score: i,
              uuid: e?.uuid ? e?.uuid : e?.id,
              fields: e?.fields?.map((f, index) => {
                let newf = {
                  ...f,
                  label: f?.name,
                  uuid: f?.uuid ? f?.uuid : f?.id,
                  placeholder: f?.helper_text,
                  type: f?.field_type?.id,
                  options:
                    f?.field_options?.map((k, ind) => {
                      return {
                        label: k?.value,
                        sort_score: ind,
                      };
                    }) || [],
                  validations: [],
                  field_type: f.field_type?.id,
                  sort_score: index,
                  required: f?.mandatory,
                  maximum_files: f?.maximum_files || 1,
                  maximum_size: f?.maximum_size || 1,
                  file_types: f?.file_types || [],
                  allow_specific_file_type:
                    f?.allow_specific_file_type || false,
                };
                delete newf.id;
                delete newf.field_options;
                return newf;
              }),
            };
            delete newData?.id;
            return newData;
          });

          service(api, {
            template_name: formTitle,
            form_status: formStatus,
            apply_on: applyOn?.id,
            linked_properties: selectedProperties?.map((item) => item.id) || [],
            org: organization?.id,
            auto_capture: captureFields,
            fill_documents: selectDocs?.map((s) => s?.id) || [],
            template: {
              label: formState?.header?.form_title,
              description: formState?.header?.form_description,
              fields: updateSections?.length > 0 ? [] : updateData,
              sections: updateSections?.length > 0 ? updateSections : [],
            },
          })
            .then((res) => {
              if (res?.data?.id) {
                const name = res?.data?.template?.fields?.find((e) => {
                  return e?.label === namefield?.name;
                });
                const email = res?.data?.template?.fields?.find((e) => {
                  return e?.label === emailfield?.name;
                });
                const phone = res?.data?.template?.fields?.find((e) => {
                  return e?.label === phonefield?.name;
                });

                submitDefaultFields(res?.data?.id, name, email, phone);
              }
            })
            .catch((error) => {
              setSaving(false);
              if (error.response?.data) {
                const returnedError = returnError(error.response?.data);
                if (
                  returnedError === 'Given token not valid for any token type'
                ) {
                  exitFromPlatform();
                } else {
                  enqueueSnackbar(returnedError, { variant: 'error' });
                  setSaving(false);
                }
              }
            });
        }
      }else{
        setSaving(true);

        let service;
        let api;

        if (id === 'new-form') {
          service = apiService.post;
          api = `form_integration/forms/`;
        } else {
          service = apiService.patch;
          api = `form_integration/forms/${id}/`;
        }

        const updateData = formState?.fields?.map((e, i) => {
          let newData = { ...e };
          newData = {
            ...e,
            label: e?.name,
            uuid: e?.uuid ? e?.uuid : e?.id,
            placeholder: e?.helper_text,
            type: e?.field_type?.id,
            options:
              e?.field_options?.map((f, i) => {
                return {
                  label: f?.value,
                  sort_score: i,
                };
              }) || [],
            validations: [],
            field_type: e?.field_type?.id,
            sort_score: i,
            required: e?.mandatory,
            maximum_files: e?.maximum_files || 1,
            maximum_size: e?.maximum_size || 1,
            file_types: e?.file_types || [],
            allow_specific_file_type: e?.allow_specific_file_type || false,
          };
          delete newData.id;
          delete newData.field_options;
          return newData;
        });

        const updateSections = formState?.sections?.map((e, i) => {
          let newData = {
            ...e,
            label: e?.section_name,
            description: e?.section_description,
            sort_score: i,
            uuid: e?.uuid ? e?.uuid : e?.id,
            fields: e?.fields?.map((f, index) => {
              let newf = {
                ...f,
                label: f?.name,
                uuid: f?.uuid ? f?.uuid : f?.id,
                placeholder: f?.helper_text,
                type: f?.field_type?.id,
                options:
                  f?.field_options?.map((k, ind) => {
                    return {
                      label: k?.value,
                      sort_score: ind,
                    };
                  }) || [],
                validations: [],
                field_type: f.field_type?.id,
                sort_score: index,
                required: f?.mandatory,
                maximum_files: f?.maximum_files || 1,
                maximum_size: f?.maximum_size || 1,
                file_types: f?.file_types || [],
                allow_specific_file_type:
                  f?.allow_specific_file_type || false,
              };
              delete newf.id;
              delete newf.field_options;
              return newf;
            }),
          };
          delete newData?.id;
          return newData;
        });

        service(api, {
          template_name: formTitle,
          form_status: formStatus,
          apply_on: applyOn?.id || null,
          linked_properties: selectedProperties?.map((item) => item.id) || [],
          org: organization?.id,
          auto_capture: captureFields,
          fill_documents: selectDocs?.map((s) => s?.id) || [],
          template: {
            label: formState?.header?.form_title,
            description: formState?.header?.form_description,
            fields: updateSections?.length > 0 ? [] : updateData,
            sections: updateSections?.length > 0 ? updateSections : [],
          },
        })
          .then((res) => {
            if (res?.data?.id) {
              const name = res?.data?.template?.fields?.find((e) => {
                return e?.label === namefield?.name;
              });
              const email = res?.data?.template?.fields?.find((e) => {
                return e?.label === emailfield?.name;
              });
              const phone = res?.data?.template?.fields?.find((e) => {
                return e?.label === phonefield?.name;
              });

              submitDefaultFields(res?.data?.id, name, email, phone);
            }
          })
          .catch((error) => {
            setSaving(false);
            if (error.response?.data) {
              const returnedError = returnError(error.response?.data);
              if (
                returnedError === 'Given token not valid for any token type'
              ) {
                exitFromPlatform();
              } else {
                enqueueSnackbar(returnedError, { variant: 'error' });
                setSaving(false);
              }
            }
          });
    }
  }
  };

  const getProperties = () => {
    setLoading(true);
    apiService
      .get(`property/prop/`)
      .then((res) => {
        setProperties(res?.data);
        if (id !== 'new-form') {
          getDetails();
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (returnedError === 'Given token not valid for any token type') {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };

  const getDocuments = () => {
    setLoading(true);
    apiService
      .get(`documents/docs/choices/`)
      .then((res) => {
        setDocumentsList(res?.data);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (returnedError === 'Given token not valid for any token type') {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };

  useEffect(() => {
    getProperties();
    getDocuments();
  }, [id]);

  const apply_on = [
    { name: 'Booking', id: 'booking' },
    { name: 'Enquiry', id: 'enquiry' },
  ];
   

  const deleteForm = () => {
    setDeleting(true);
    apiService
      .delete(`form_integration/forms/${id}/`)
      .then((res) => {
        setDeleting(false);
        enqueueSnackbar('Form Deleted', { variant: 'success' });
        setTimeout(() => {
          navigate('/forms');
        }, 2000);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (returnedError === 'Given token not valid for any token type') {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setDeleting(false);
          }
        }
      });
  };

  useEffect(() => {
    if (formState) {
      if (formState?.sections?.length > 0) {
        const newFields = formState?.sections
          ?.map((e) => {
            return e?.fields;
          })
          ?.flat();
        setAllFields(newFields);
      } else {
        if (formState?.fields?.length > 0) {
          setAllFields(formState?.fields);
        } else {
          setAllFields([]);
        }
      }
    }
  }, [formState]);

   
  const modifyAndReturnData = () => {
    const values = {};
    if (formState?.fields?.length > 0 && formState?.sections?.length === 0) {
      const newFields = formState?.fields?.map((e) => {
        if (e?.field_type?.id === 'CHECKBOX') {
          values[convertStringToKeys(e?.name)] = false;
        } else if (e?.field_type?.id === 'RADIO') {
          values[convertStringToKeys(e?.name)] = false;
        } else if (e?.field_type?.id === 'TEXT') {
          values[convertStringToKeys(e?.name)] = '';
        } else if (e?.field_type?.id === 'NAME') {
          values[convertStringToKeys(e?.name)] = '';
        } else if (e?.field_type?.id === 'DROPDOWN') {
          values[convertStringToKeys(e?.name)] = { name: '', id: '' };
        } else if (e?.field_type?.id === 'MULTISELECT') {
          values[convertStringToKeys(e?.name)] = [];
        } else if (e?.field_type?.id === 'SIGNATURE') {
          values[convertStringToKeys('signature')] = '';
       }else {
          values[convertStringToKeys(e?.name)] = '';
        }
        if (e?.field_type?.id === 'DROPDOWN') {
          console.log('from dsadsad', e);
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'select',
            fieldProps: {
              defaultOptions: e?.field_options?.map((g) => {
                return {
                  id: g?.id,
                  name: g?.value,
                };
              }),
              titleKey: 'name',
              sx:
                e?.type === 'CHECKBOX'
                  ? {
                      marginLeft: '-10px',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }
                  : {},
              isCustomizingOptions: true,
              tooltip: e?.helper_text || '',
            },
          };
        } else if (e?.field_type?.id === 'MULTISELECT') {
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'select',
            tooltip: e?.helper_text || '',
            fieldProps: {
              multiple: true,
              defaultOptions: e?.field_options?.map((g) => {
                return {
                  id: g?.id,
                  name: g?.value,
                };
              }),
              titleKey: 'name',
              sx:
                e?.type === 'CHECKBOX'
                  ? {
                      marginLeft: '-10px',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }
                  : {},
              isCustomizingOptions: true,
              tooltip: e?.helper_text || '',
            },
          };
        } else if (e?.field_type?.id === 'CHECKBOX') {
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'checkbox',
            tooltip: e?.helper_text || '',
            fieldProps: {
              sx:
                e?.type === 'CHECKBOX'
                  ? {
                      marginLeft: '-10px',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }
                  : {},
              tooltip: e?.helper_text || '',
            },
          };
        } else if (e?.field_type?.id === 'RADIO') {
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'radio_groups',
            tooltip: e?.helper_text || '',
            fieldProps: {
              sx:
                e?.type === 'CHECKBOX'
                  ? {
                      marginLeft: '-10px',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }
                  : {},
              tooltip: e?.helper_text || '',
              options: e?.field_options || [],
            },
          };
        } else if (e?.field_type?.id === 'FILEUPLOAD') {
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'file_upload',
            tooltip: e?.helper_text || '',
            fieldProps: {
              notToUpload: true,
              maximum_files: e?.maximum_files,
              maximum_size: e?.maximum_size,
              field: e,
              tooltip: e?.helper_text || '',
            },
          };
        } else if (e?.field_type?.id === 'PHONE') {
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'phone',
            tooltip: e?.helper_text || '',
            fieldProps: {
              sx:
                e?.type === 'CHECKBOX'
                  ? {
                      marginLeft: '-10px',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }
                  : {},
              tooltip: e?.helper_text || '',
            },
          };
        } else if (e?.field_type?.id === 'CURRENCY') {
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'currency',
            tooltip: e?.helper_text || '',
            fieldProps: {
              sx:
                e?.type === 'CHECKBOX'
                  ? {
                      marginLeft: '-10px',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }
                  : {},
              tooltip: e?.helper_text || '',
            },
          };
        } else if (e?.field_type?.id === 'TEXTAREA') {
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'textarea',
            fieldProps: {
              sx:
                e?.type === 'CHECKBOX'
                  ? {
                      marginLeft: '-10px',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }
                  : {},
              tooltip: e?.helper_text || '',
            },
          };
        } else if (e?.field_type?.id === 'SIGNATURE') {
          return {
           name: convertStringToKeys('signature'),
           label: e?.name,
           placeholder: e?.name,
           type: 'signature',
           tooltip: e?.helper_text,
           fieldProps: {
            tooltip: e?.helper_text,
           }
          }
       }else {
          console.log('From helper', e);
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'text',
            tooltip: e?.helper_text || '',
            fieldProps: {
              sx:
                e?.type === 'CHECKBOX'
                  ? {
                      marginLeft: '-10px',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }
                  : {},
              tooltip: e?.helper_text || '',
            },
          };
        }
      });

      const newData = {
        fields: newFields,
        defaultValues: values,
      };
      return newData;
    } else {
      const newFields = formState?.sections?.map((e) => {
        return {
          name: '',
          type: 'box_layout',
          label: e?.section_name,
          description: e?.section_description,
          borderType: 'none',
          fieldProps: {
            arrayFields: e?.fields?.map((e) => {
              if (e?.field_type?.id === 'CHECKBOX') {
                values[convertStringToKeys(e?.name)] = false;
              } else if (e?.field_type?.id === 'TEXT') {
                values[convertStringToKeys(e?.name)] = '';
              } else if (e?.field_type?.id === 'DROPDOWN') {
                values[convertStringToKeys(e?.name)] = { name: '', id: '' };
              } else if (e?.field_type?.id === 'MULTISELECT') {
                values[convertStringToKeys(e?.name)] = [];
              }else if (e?.field_type?.id === 'SIGNATURE') {
                values[convertStringToKeys('signature')] = '';
              } else {
                values[convertStringToKeys(e?.name)] = '';
              }
              if (e?.field_type?.id === 'DROPDOWN') {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'select',
                  tooltip: e?.helper_text || '',
                  fieldProps: {
                    defaultOptions: e?.field_options?.map((g) => {
                      return {
                        ...g,
                        name: g?.value,
                      };
                    }),
                    titleKey: 'name',
                    sx:
                      e?.type === 'CHECKBOX'
                        ? {
                            marginLeft: '-10px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }
                        : {},
                    isCustomizingOptions: true,
                    tooltip: e?.helper_text || '',
                  },
                };
              } else if (e?.field_type?.id === 'MULTISELECT') {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'select',
                  tooltip: e?.helper_text || '',
                  fieldProps: {
                    defaultOptions: e?.field_options?.map((g) => {
                      return {
                        ...g,
                        name: g?.value,
                      };
                    }),
                    multiple: true,
                    titleKey: 'name',
                    titleKey: 'value',
                    sx:
                      e?.type === 'CHECKBOX'
                        ? {
                            marginLeft: '-10px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }
                        : {},
                    isCustomizingOptions: true,
                    tooltip: e?.helper_text || '',
                  },
                };
              } else if (e?.field_type?.id === 'CHECKBOX') {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'checkbox',
                  fieldProps: {
                    sx:
                      e?.type === 'CHECKBOX'
                        ? {
                            marginLeft: '-10px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }
                        : {},
                    tooltip: e?.helper_text || '',
                  },
                };
              } else if (e?.field_type?.id === 'RADIO') {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'radio_groups',
                  fieldProps: {
                    sx:
                      e?.type === 'CHECKBOX'
                        ? {
                            marginLeft: '-10px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }
                        : {},
                    tooltip: e?.helper_text || '',
                    options: e?.field_options || [],
                  },
                };
              } else if (e?.field_type?.id === 'FILEUPLOAD') {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'file_upload',
                  tooltip: e?.helper_text || '',
                  fieldProps: {
                    notToUpload: true,
                    maximum_files: e?.maximum_files,
                    maximum_size: e?.maximum_size,
                    field: e,
                    tooltip: e?.helper_text || '',
                  },
                };
              } else if (e?.field_type?.id === 'PHONE') {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'phone',
                  tooltip: e?.helper_text || '',
                  fieldProps: {
                    sx:
                      e?.type === 'CHECKBOX'
                        ? {
                            marginLeft: '-10px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }
                        : {},
                    tooltip: e?.helper_text || '',
                  },
                };
              } else if (e?.field_type?.id === 'CURRENCY') {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'currency',
                  tooltip: e?.helper_text || '',
                  fieldProps: {
                    sx:
                      e?.type === 'CHECKBOX'
                        ? {
                            marginLeft: '-10px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }
                        : {},
                    tooltip: e?.helper_text || '',
                  },
                };
              } else if (e?.field_type?.id === 'TEXTAREA') {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'textarea',
                  tooltip: e?.helper_text || '',
                  fieldProps: {
                    sx:
                      e?.type === 'CHECKBOX'
                        ? {
                            marginLeft: '-10px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }
                        : {},
                    tooltip: e?.helper_text || '',
                  },
                };
              } else if (e?.field_type?.id === 'SIGNATURE') {
                 return {
                  name: convertStringToKeys('signature'),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'signature',
                  tooltip: e?.helper_text,
                  fieldProps: {
                    tooltip: e?.helper_text,
                   }
                 }
              }else {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'text',
                  tooltip: e?.helperText,
                  fieldProps: {
                    sx:
                      e?.type === 'CHECKBOX'
                        ? {
                            marginLeft: '-10px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }
                        : {},
                    tooltip: e?.helper_text || '',
                  },
                };
              }
            }),
          },
        };
      });

      const newData = {
        fields: newFields,
        defaultValues: values,
      };
      return newData;
    }
  };

  const getDynamicSchema = () => {
    let schemaFields = {};
    if (formState?.fields?.length > 0 && formState?.sections?.length === 0) {
      formState?.fields?.map((e) => {
        if (e?.field_type?.id === 'CHECKBOX' || e?.field_type?.id === 'RADIO') {
          schemaFields[convertStringToKeys(e?.name)] = yup
            .boolean()
            .oneOf([true], 'You must agree to the terms and conditions')
            .required('You must agree to the terms and conditions');
        } else if (e?.field_type?.id === 'PHONE') {
          schemaFields[convertStringToKeys(e?.name)] = yup
            .string()
            .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
            .required('Phone number is required');
        } else if (e?.field_type?.id === 'TEXT') {
          schemaFields[convertStringToKeys(e?.name)] = yup
            .string()
            .required('This field is required');
        } else if (e?.field_type?.id === 'EMAIL') {
          schemaFields[convertStringToKeys(e?.name)] = yup
            .string()
            .email('Invalid email address')
            .required('Email is required');
        } else if (
          e?.field_type?.id === 'DROPDOWN' ||
          e?.field_type?.id === 'MULTISELECT'
        ) {
          schemaFields[convertStringToKeys(e?.name)] = yup.object().shape({
            value: yup.string().required('This field is required'),
            id: yup.string().required('This field is required'),
          });
        } else {
          schemaFields[convertStringToKeys(e?.name)] = yup
            .string()
            .required('This field is required');
        }
      });
    } else {
      formState?.sections?.forEach((sec) => {
        sec?.fields?.forEach((e) => {
          if (
            e?.field_type?.id === 'CHECKBOX' ||
            e?.field_type?.id === 'RADIO'
          ) {
            schemaFields[convertStringToKeys(e?.name)] = yup
              .boolean()
              .oneOf([true], 'You must agree to the terms and conditions')
              .required('You must agree to the terms and conditions');
          } else if (e?.field_type?.id === 'PHONE') {
            schemaFields[convertStringToKeys(e?.name)] = yup
              .string()
              .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
              .required('Phone number is required');
          } else if (e?.field_type?.id === 'TEXT') {
            schemaFields[convertStringToKeys(e?.name)] = yup
              .string()
              .required('This field is required');
          } else if (e?.field_type?.id === 'EMAIL') {
            schemaFields[convertStringToKeys(e?.name)] = yup
              .string()
              .email('Invalid email address')
              .required('Email is required');
          } else if (
            e?.field_type?.id === 'DROPDOWN' ||
            e?.field_type?.id === 'MULTISELECT'
          ) {
            schemaFields[convertStringToKeys(e?.name)] = yup.object().shape({
              value: yup.string().required('This field is required'),
              id: yup.string().required('This field is required'),
            });
          } else {
            schemaFields[convertStringToKeys(e?.name)] = yup
              .string()
              .required('This field is required');
          }
        });
      });
    }
    console.log(schemaFields);
    return yup.object().shape(schemaFields);
  };

  const fromButtonText = `<div id="form_container" style="width: 100%;position: relative;"> 
    </div> 
    <script defer src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script> 
  <script defer> 
  var formPath = "form_integration/public_forms/${id}";
  var containerId = 'form_container'; 
  </script> 
  <script defer src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.11.0/umd/popper.min.js" crossorigin="anonymous"></script> 
  <script defer src="${BASE_URI}static/js/form.js"></script>`;

  const navigateBackToForms = () => {
    navigate('/forms');
  };

  const onClickFormSection = () => {
    const returnToAddData = () => {
      if (
        formState?.sections?.length === 0 &&
        formState?.fields?.length === 0
      ) {
        return [
          {
            section_name: '',
            section_description: '',
            fields: [
              {
                name: '',
                field_type: '',
                id: v4(),
                mandatory: true,
                helper_text: '',
                allow_specific_file_type: false,
                file_types: [],
                maximum_files: 1,
                maximum_size: 1,
              },
            ],
          },
        ];
      } else if (
        formState?.sections?.length === 0 &&
        formState?.fields?.length > 0
      ) {
        return [
          {
            id: v4(),
            section_name: 'Section 1',
            section_description: '',
            fields: [...formState?.fields],
          },
          {
            id: v4(),
            section_name: '',
            section_description: '',
            fields: [
              {
                name: '',
                field_type: '',
                id: v4(),
                mandatory: true,
                helper_text: '',
                allow_specific_file_type: false,
                file_types: [],
                maximum_files: 1,
                maximum_size: 1,
              },
            ],
          },
        ];
      } else if (formState?.sections?.length > 0) {
        return [
          ...formState?.sections,
          {
            section_name: '',
            section_description: '',
            fields: [
              {
                name: '',
                field_type: '',
                id: v4(),
                mandatory: true,
                helper_text: '',
                allow_specific_file_type: false,
                file_types: [],
                maximum_files: 1,
                maximum_size: 1,
              },
            ],
          },
        ];
      }
    };
    let cloneFormState = { ...formState };
    cloneFormState = {
      ...cloneFormState,
      fields: [],
      sections: returnToAddData(),
    };
    console.log('from sect......', cloneFormState);
    setFormState(cloneFormState);
  };

  const renderSectionContainer = useCallback(() => {
    return formState?.sections?.map((section, sectionIndex) => {
      return (
        <StepperContainer
          section={section}
          sectionIndex={sectionIndex}
          setFormState={setFormState}
          formState={formState}
          onClickFormSection={onClickFormSection}
        />
      );
    });
  }, [formState]);
  
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Box
            sx={{
              borderBottom: BoxEffects.borderBottom,
              borderColor: 'secondary.main',
              padding: BoxEffects.padding,
              marginBottom: BoxEffects.marginButton,
              paddingLeft: BoxEffects.margin,
              paddingRight: headerButton.marginRigt,
              position: 'sticky',
              top: '65px',
              backgroundColor: 'white',
              zIndex: 99,
            }}
          >
            <BreadCrumbWithActions propArr={breadcrumb}>
              {id !== 'new-form' && (
                <div className="flex items-center w-full">
                  <PrimaryBtn
                    title="Share"
                    onClick={handleOpenShareLink}
                    style={{
                      boxShadow: 'none',
                      fontWeight: 300,
                    }}
                    disabled={formDetails?.form_status === 'inactive'}
                  />
                  <SecondaryBtn
                    title="Preview"
                    style={{
                      boxShadow: 'none',
                      fontWeight: 300,
                    }}
                    onClick={handlePreview}
                  />
                  <ErrorBtn
                    title="Delete"
                    disabled={deleting}
                    style={{
                      boxShadow: 'none',
                      fontWeight: 300,
                    }}
                    onClick={deleteForm}
                  />
                  <PrimaryBtn
                    title="Integrate"
                    //   disabled={isSubmitting}
                    onClick={handleModal}
                    style={{
                      boxShadow: 'none',
                      fontWeight: 300,
                    }}
                  />
                  <PrimaryBtn
                    title="Save"
                    disabled={saving}
                    onClick={editFormDetails}
                    style={{
                      boxShadow: 'none',
                      marginRight: '0px !important',
                      fontWeight: 300,
                    }}
                  />
                </div>
              )}
              {id === 'new-form' && (
                <div className="flex items-center w-full">
                  <SecondaryBtn
                    title="Cancel"
                    style={{
                      boxShadow: 'none',
                      fontWeight: 300,
                    }}
                    onClick={navigateBackToForms}
                  />

                  <SecondaryBtn
                    title="Preview"
                    style={{
                      boxShadow: 'none',
                      fontWeight: 300,
                    }}
                    onClick={handlePreview}
                  />

                  <PrimaryBtn
                    title="Save"
                    disabled={saving}
                    onClick={editFormDetails}
                    style={{
                      boxShadow: 'none',
                      marginRight: '0px !important',
                      fontWeight: 300,
                    }}
                  />
                </div>
              )}
            </BreadCrumbWithActions>
          </Box>
          <div className="m-6">
            <GridComponent>
              <Grid item xs={grids.parrentgrid} lg={grids.overviewFirstGrid}>
                <div
                  className="flex justify-between group"
                  ref={sectionRef}
                  onClick={handleClickInside}
                >
                  {isActive && (
                    <>
                      <div className="flex flex-col justify-center bg-[#EDECF9] w-11/12">
                        <div className="mx-4 my-4 flex flex-col justify-center">
                          <TextField
                            className="!w-3/4"
                            value={formState?.header?.form_title}
                            defaultValue={formHeader}
                            placeholder="Form Heading"
                            sx={{
                              background: 'white',
                              '& .MuiFormHelperText-root': {
                                backgroundColor: '#EDECF9',
                                margin: '0px !important',
                              },
                            }}
                            error={error.title}
                            helperText={
                              error.title
                                ? error?.title
                                : 'Maximum character limit is 250'
                            }
                            onChange={handleFormTitle}
                          />
                          <TextField
                            className="!w-3/4"
                            value={formState?.header?.form_description}
                            defaultValue={formDescription}
                            placeholder="Form Description (Optional)"
                            sx={{
                              background: 'white',
                              '& .MuiFormHelperText-root': {
                                backgroundColor: '#EDECF9',
                                margin: '0px !important',
                              },
                            }}
                            error={error.description}
                            helperText={
                              error.description
                                ? error.description
                                : 'Maximum character limit is 1000'
                            }
                            onChange={handleFormDescription}
                          />
                        </div>
                      </div>
                      <div className="hidden group-hover:block">
                        <FormToolbar
                          onClickForNewFields={() => {
                            let cloneFormState = { ...formState };
                            cloneFormState = {
                              ...cloneFormState,
                              fields: [
                                ...cloneFormState?.fields,
                                {
                                  name: '',
                                  field_type: '',
                                  id: v4(),
                                  mandatory: true,
                                  helper_text: '',
                                  allow_specific_file_type: false,
                                  file_types: [],
                                  maximum_files: 1,
                                  maximum_size: 1,
                                },
                              ],
                            };
                            setFormState(cloneFormState);
                          }}
                          onClickFormSection={onClickFormSection}
                        />
                      </div>
                    </>
                  )}
                  {!isActive && (
                    <>
                      <div
                        className="rounded-lg w-11/12"
                        style={{
                          border: '1px solid #8E8CA3',
                        }}
                      >
                        <div className="flex flex-col m-8">
                          <div
                            className="text-[#353349] mb-4"
                            style={{
                              fontFamily: 'Rubik',
                              fontSize: '20px',
                              fontWeight: 400,
                              lineHeight: '23.7px',
                            }}
                          >
                            {formState?.header?.form_title === ''
                              ? 'Form heading'
                              : formState?.header?.form_title}
                          </div>
                          <div
                            className="text-[#8E8CA3] break-words"
                            style={{
                              fontFamily: 'Rubik',
                              fontSize: '16px',
                              fontWeight: 400,
                              lineHeight: '18.96px',
                            }}
                          >
                            {formState?.header?.form_description === ''
                              ? 'Form Description (Optional)'
                              : formState?.header?.form_description}
                          </div>
                        </div>
                      </div>
                      <div className="hidden group-hover:block">
                        <FormToolbar
                          onClickForNewFields={() => {
                            let cloneFormState = { ...formState };
                            cloneFormState = {
                              ...cloneFormState,
                              fields: [
                                ...cloneFormState?.fields,
                                {
                                  name: '',
                                  field_type: '',
                                  id: v4(),
                                  mandatory: true,
                                  helper_text: '',
                                  allow_specific_file_type: false,
                                  file_types: [],
                                  maximum_files: 1,
                                  maximum_size: 1,
                                },
                              ],
                            };
                            setFormState(cloneFormState);
                          }}
                          onClickFormSection={onClickFormSection}
                        />
                      </div>
                    </>
                  )}
                </div>

                {formState?.sections?.length > 0 && renderSectionContainer()}
                {!formState?.sections?.length > 0 && (
                  <FieldComponent
                    formState={formState}
                    formTitle={formTitle}
                    setFormState={setFormState}
                    onClickFormSection={onClickFormSection}
                  />
                )}
              </Grid>
              <Grid
                className="!md:w-full"
                item
                xs={grids.childgrid}
                lg={grids.childgrid}
              >
                <DetailsCard cardHeader="Actions">
                  <div className="w-full">
                    <TextField
                      fullWidth
                      label="Title"
                      defaultValue={formTitle}
                      placeholder="Enter Title"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setFormTitle(e.target.value);
                      }}
                    />
                  </div>

                  <div className="w-full">
                    <Autocomplete
                      multiple
                      id="property"
                      value={selectedProperties}
                      onChange={(event, newValue) => {
                        handleLinkedProperties(newValue);
                      }}
                      options={properties}
                      popupIcon={<ExpandMore className="!fill-[#8E8CA3]" />}
                      getOptionLabel={(option) => {
                        return option?.name;
                      }}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option?.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Property"
                          placeholder="Property"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="w-full">
                    <Autocomplete
                      id="property"
                      value={applyOn}
                      onChange={(event, newValue) => {
                        setApplyOn(newValue);
                      }}
                      options={apply_on}
                      popupIcon={<ExpandMore className="!fill-[#8E8CA3]" />}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Apply On"
                          placeholder="Apply On"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="flex items-center mt-2.5">
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formStatus}
                        label=""
                        role="select-status"
                        IconComponent={(props) => {
                          if (
                            props?.className?.includes('MuiSelect-iconOpen')
                          ) {
                            return (
                              <ExpandLess
                                {...props}
                                className="!fill-[#8E8CA3]"
                                sx={{
                                  pointerEvents: 'none !important',
                                  position: 'absolute !important',
                                  right: '5px !important',
                                }}
                              />
                            );
                          }
                          return (
                            <ExpandMore
                              {...props}
                              sx={{
                                pointerEvents: 'none !important',
                                position: 'absolute !important',
                                right: '5px !important',
                              }}
                              className="!fill-[#8E8CA3]"
                            />
                          );
                        }}
                        onChange={(e) => toggleStatus(e)}
                        sx={{
                          height: '56px',
                          background: 'white !important',
                          color:
                            formStatus === 'active' ? '#24D315' : '#FF5C5C',
                        }}
                      >
                        <MenuItem
                          value="active"
                          sx={{ color: '#24D315 !important' }}
                        >
                          Active
                        </MenuItem>
                        <MenuItem
                          value="inactive"
                          sx={{ color: '#FF5C5C !important' }}
                        >
                          Inactive
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="flex flex-col mt-2.5">
                    {applyOn?.id === 'enquiry' || applyOn?.id === 'booking' ? (
                      <>
                        <div className="w-full">
                          <Autocomplete
                            id="Select Name Field"
                            value={namefield}
                            onChange={(event, newValue) => {
                              setNameField(newValue);
                            }}
                            options={allfields}
                            popupIcon={
                              <ExpandMore className="!fill-[#8E8CA3]" />
                            }
                            getOptionLabel={(option) => {
                              return option.name;
                            }}
                            renderTags={(tagValue, getTagProps) =>
                              tagValue.map((option, index) => (
                                <Chip
                                  label={option.name}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Link Name"
                                required
                                placeholder="Link Name"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="w-full">
                          <Autocomplete
                            id="Select Email Field"
                            value={emailfield}
                            onChange={(event, newValue) => {
                              setEmailField(newValue);
                            }}
                            options={allfields}
                            popupIcon={
                              <ExpandMore className="!fill-[#8E8CA3]" />
                            }
                            getOptionLabel={(option) => {
                              return option.name;
                            }}
                            renderTags={(tagValue, getTagProps) =>
                              tagValue.map((option, index) => (
                                <Chip
                                  label={option.name}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Link Email"
                                required
                                placeholder="Link Email"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="w-full">
                          <Autocomplete
                            id="Select Phone Field"
                            value={phonefield}
                            onChange={(event, newValue) => {
                              setPhoneField(newValue);
                            }}
                            options={allfields}
                            popupIcon={
                              <ExpandMore className="!fill-[#8E8CA3]" />
                            }
                            getOptionLabel={(option) => {
                              return option.name;
                            }}
                            renderTags={(tagValue, getTagProps) =>
                              tagValue.map((option, index) => (
                                <Chip
                                  label={option.name}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Link Phone"
                                required
                                placeholder="Link Phone"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="w-full ml-[-10px] flex items-center">
                          <Checkbox
                            checked={captureFields}
                            onChange={handleCapture}
                          />
                          <div
                            className="ml-2 text-[#353349]"
                            style={{
                              fontWeight: 400,
                            }}
                          >
                            Capture AI
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="w-full">
                    <Autocomplete
                      id="documents"
                      value={selectDocs || []}
                      onChange={(event, newValue) => {
                        setSelectedDocs(newValue);
                      }}
                      multiple
                      options={documentsList}
                      popupIcon={<ExpandMore className="!fill-[#8E8CA3]" />}
                      getOptionLabel={(option) => {
                        return option.title;
                      }}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option.title}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          required
                          placeholder="Fill Documents"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </div>
                </DetailsCard>
              </Grid>
            </GridComponent>
          </div>

          {openIntegrationModal && (
            <Integration
              title={'Integration'}
              open={openIntegrationModal}
              onClose={handleModal}
              text={fromButtonText}
            />
          )}


          {openShareLink && (
            <Integration
              title={'Share Link'}
              open={openShareLink}
              onClose={handleOpenShareLink}
              message='Link Copied'
              rows={2}
              text={`${window.location.origin}/public-form/form/${formDetails?.uuid}`}
            />
          )}


          {openPreview && (
            <FormPreview
              title={formState?.header?.form_title}
              description={formState?.header?.form_description}
              open={openPreview}
              onClose={handlePreview}
              formFields={modifyAndReturnData(formDetails?.template)}
              yupSchema={getDynamicSchema()}
            />
          )}
        </>
      )}
    </>
  );
};

export default FormDetail;
