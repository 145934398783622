import React, { useState } from 'react';
import FormModal from '../../../shared-component/formmodal-component/FormModal';
import FormManager from '../../../shared-component/formrelated-component/FormManager';
import { apiService } from '../../../../constant/AuthConstant';
import { enqueueSnackbar } from 'notistack';
import returnError from '../../../../utils/ExportError';
import useExit from '../../../../utils/useExit';

const AddKeyModal = ({ open, onClose, data, onSuccessCallback }) => {
  const { exitFromPlatform } = useExit();
  const [loading, setLoading] = useState(false);

  const defaultValues = {
    stripe_api_publishable_key: '',
    stripe_api_secret_key: '',
  };

  const fieldMap = [
    {
      name: 'stripe_api_publishable_key',
      label: 'Publishable Key',
      placeholder: 'Publishable Key',
      type: 'text',
    },
    {
      name: 'stripe_api_secret_key',
      label: 'Secret Key',
      placeholder: 'Secret Key',
      type: 'text',
    },
  ];

  const onSubmit = (payload) => {
    if(payload?.stripe_api_publishable_key?.trim()?.length === 0){
       enqueueSnackbar("Please add Publishable Key", { variant: 'error' })
    }
    else if(payload?.stripe_api_secret_key?.trim()?.length === 0){
      enqueueSnackbar("Please add Secret Key", { variant: 'error' })
    }
    else{
      setLoading(true);
    apiService
      .post(`organization/get-user-org/`, payload)
      .then((res) => {
        if (res?.data) {
           if(data?.stripe_api_publishable_key !== ""){
            enqueueSnackbar("Updated", { variant: 'success' })
           }else{
            enqueueSnackbar("Added", { variant: 'success' })
           }
           onSuccessCallback()
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
    }
  };
  return (
    <FormModal title="Add Key" open={open} onClose={onClose}>
      <div className="mt-4">
        <FormManager
          data={data?.stripe_api_publishable_key !== '' ? data : {}}
          loading={loading}
          onCancel={onClose}
          fieldMap={fieldMap}
          formSubmit={onSubmit}
          defaultValues={defaultValues}
        />
      </div>
    </FormModal>
  );
};

export default AddKeyModal;
