import React, { useEffect, useState } from 'react';
import BreadCrumbWithActions from '../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import { BoxEffects, headerButton } from '../../../Config/Constants';
import Box from '@mui/material/Box';

import { useSnackbar } from 'notistack';
import Loading from '../../shared-component/Loading/Loading';
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn';
import useExit from '../../../utils/useExit';
import LabTab from '../../../publishcomponents-20230906T111139Z-001/publishcomponents/tabcomponent/src/LabTabs';
import BookingComponent from './component/BookingComponent';
import AddBooking from './component/AddBooking';
import { apiService } from '../../../constant/AuthConstant';

import returnError from '../../../utils/ExportError';
import SearchBar from '../../shared-component/searchbar/SearchBar';

const Booking = () => {
  const arr1 = [{ title: 'Bookings', url: '/' }];
  const [loading, setLoading] = useState(false);
  const { exitFromPlatform } = useExit();
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState('1');
  const [bookingList, setBookingList] = useState([]);
  const [openBooking, setOpenBooking] = useState(false);
  const [organization, setOrganization] = useState({});
  const [count, setCount] = useState(1);
  const [formSubmissionList, setFormSubmissionsList] = useState([]);

  const handleModal = () => {
    setOpenBooking(!openBooking);
  };

  const handleTabChange = (event, newVal) => {
    setValue(newVal);
  };

  const getFormSubmissions = () =>{
    apiService.get(`form_integration/submissions/?ftype=enquiry`).then((res) =>{
      setFormSubmissionsList(res?.data)
      localStorage.removeItem('form-tab')
      setLoading(false)
    }).catch((error) =>{
        if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
              setLoading(false)
          }
        }
    })
  }

  const listBooking = () => {
    setLoading(true);
    apiService
      .get(`booking/entries/`)
      .then((res) => {
        if (res?.data?.length > 0) {
          setCount(res?.data);
           console.log()
          const new_n_Bookings = res?.data?.map((e) => {
            if (e?.length > 1) {
              const arrayData = [...e];
              arrayData.shift();
              
            
              return {
                ...e[0],
                history: arrayData,
              };
            } else {
              return {
                ...e[0],
                history: [],
              };
            }
          });
          console.log('from kkfllldkflf', new_n_Bookings)
          const newBookings = res?.data?.map((e) => {
            
            if (e?.length > 1) {
              const arrayData = [...e].map((p) =>{
                if(p?.form?.submission && JSON.parse(p?.form?.submission)?.name){
                  return {
                    ...p,
                    ...JSON.parse(p?.form?.submission),
                    property: p?.property,
                    unit: p?.unit,
                    history: [],
                  };
                }else{
                  return {
                    ...p,
                    history: [],
                  };
                }
              });

              arrayData.shift();
              if(e[0]?.form?.submission && JSON.parse(e[0]?.form?.submission)?.name){
                return {
                  ...e[0],
                  ...JSON.parse(e[0]?.form?.submission),
                  property: e[0]?.property,
                  unit: e[0]?.unit,
                  history: arrayData,
                };
              }else{
                return {
                  ...e[0],
                  history: arrayData,
                };
              }
            } else {
              if(e[0]?.form?.submission && JSON.parse(e[0]?.form?.submission)?.name){
                return {
                  ...e[0],
                  ...JSON.parse(e[0]?.form?.submission),
                  property: e[0]?.property,
                  unit: e[0]?.unit,
                  history: [],
                };
              }
              return {
                ...e[0],
                history: [],
              };
            }
          });

          setBookingList(newBookings);
        }
        getFormSubmissions()
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (returnedError === 'Given token not valid for any token type') {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };

  async function fetchDataFromCollection() {
    apiService
      .get(`organization/get-user-org/`)
      .then((res) => {
        if (res.data) {
          setOrganization(res.data);
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoad(false);
          }
        }
      });
  }

  useEffect(() => {
    listBooking();
    fetchDataFromCollection();
  }, []);

  return (
    <>
      <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: 'secondary.main',
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          paddingLeft: BoxEffects.margin,
          paddingRight: headerButton.marginRigt,
          position: 'sticky',
          top: '65px',
          background: 'white',
          zIndex: 99,
        }}
      >
        <BreadCrumbWithActions propArr={arr1}>
          <div className="flex justify-between items-center">
            <div
              style={{
                marginLeft: '20px',
              }}
            >
              <PrimaryBtn
                className="!mx-0"
                style={{
                  boxShadow: 'none',
                  margin: '0px !important',
                  fontWeight: 300,
                }}
                title="Add New"
                onClick={handleModal}
              />
            </div>
          </div>
        </BreadCrumbWithActions>
      </Box>
      <div className="mx-6 my-6">
        <BookingComponent
          rows={bookingList}
          loading={loading}
          count={count}
          listBooking={listBooking}
          formSubmissionList={formSubmissionList}
        />
      </div>

      {openBooking && (
        <AddBooking
          open={openBooking}
          onClose={handleModal}
          title="Add Booking"
          successCallback={() => {
            listBooking();
          }}
          organization={organization}
        />
      )}
    </>
  );
};

export default Booking;
